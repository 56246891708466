import { Component, OnInit } from '@angular/core';
import { ApicallsService } from '../services/apicalls.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { AlertService } from '../services/alert.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss']
})
export class DeleteComponent implements OnInit {

  code: string;
  showErrorModal = false;
  showSuccessModal = false;
  messageErrorModal = '';
  activo = true;
  finished: boolean;

  constructor(private route: ActivatedRoute,
              private api: ApicallsService,
              private router: Router,
              private spinner: NgxSpinnerService,
              private authenticationService: AuthenticationService,
              private alertService: AlertService) {
  }

  ngOnInit() {
    const limite = moment('2024-05-05').startOf('day');
    this.finished = false;
    if(moment().startOf('day').isAfter(limite)){
      this.finished = true;
    }
    if(!this.finished){
      this.route.params.subscribe( params => {
        this.code = params['code'];
        this.delete();
      });
    }
  }



  delete() {
    this.api.deleteReservaWithCode(this.code).subscribe((data: any) => {
        if (data.error) {
          this.messageErrorModal = data.error;
          this.showErrorModal = true;
        } else {
          this.showSuccessModal = true;
        }
    }, error => {
      this.messageErrorModal = 'Se ha producido un error en el proceso. Por favor inténtelo de nuevo';
      this.showErrorModal = true;
    });
  }

  closeModals () {
    this.showErrorModal = false;
    this.showSuccessModal = false;
  }

}
