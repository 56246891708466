import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApicallsService } from '../services/apicalls.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { AlertService } from '../services/alert.service';
import * as moment from 'moment';
import swal from 'sweetalert2';
import toastr from 'toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-home-test',
  templateUrl: './home-test.component.html',
  styleUrls: ['./home-test.component.scss']
})
export class HomeTestComponent implements OnInit {

  formRegister: FormGroup;
  showErrorModal = false;
  showSuccessModal = false;
  messageErrorModal = '';
  errorname = false;
  errormail = false;
  errorpeople = false;
  forminvalid = false;
  catselected = false;
  selectNinos = false;
  selectChicas = false;
  selectChicos = false;
  sending = false;
  activo = true;
  categories = [
    {
      id: 1,
      nombre: 'PEQUES 5 A 10 AÑOS',
      selected: false
    },
    {
      id: 2,
      nombre: 'JUNIOR 11 A 17 AÑOS',
      selected: false
    },
    {
      id: 3,
      nombre: 'JÓVENES 18 A 29 AÑOS',
      selected: false
    },
    {
      id: 4,
      nombre: 'ADULTOS 30 A 49 AÑOS',
      selected: false
    },
    {
      id: 5,
      nombre: 'SENIOR +50 AÑOS',
      selected: false
    },
    /*{
      id: 6,
      nombre: 'CHICAS DE 18 A 29 AÑOS',
      selected: false
    },
    {
      id: 7,
      nombre: 'MUJERES DE 30 A 49 AÑOS',
      selected: false
    },
    {
      id: 8,
      nombre: 'HOMBRES DE 30 A 49 AÑOS',
      selected: false
    },
    {
      id: 9,
      nombre: 'MUJERES + DE 50 AÑOS',
      selected: false
    },
    {
      id: 10,
      nombre: 'HOMBRES + DE 50 AÑOS',
      selected: false
    }*/
  ];
  viewAuth: boolean;
  constructor(private formBuilder: FormBuilder,
              private api: ApicallsService) {
  }

  ngOnInit() {
    this.formRegister = this.formBuilder.group({
        category: ['', Validators.required],
        categoryname: [''],
        nombre: [null, Validators.required],
        apellidos: [null, Validators.required],
        email: [null, [ Validators.required, Validators.email]],
        dni: [null, Validators.required],
        telefono: [null, Validators.required],
        postal: [null, Validators.required],
        nombretutor: [null],
        autorizado: [null],
        edad: [null, Validators.required],
        fecha: [null, Validators.required],
        webmn4: [false],
        amigos: [false],
        radio: [false],
        redes: [false],
        otro: [false],
        otrodesc: [''],
        dia: [''],
        accept: [null]
    });

  }

  selectCat(item) {
    const limite = moment('2024-05-05');
    const now = moment();

    if (limite.isSameOrBefore(now)) {
      this.messageErrorModal = 'Lo sentimos, pero ya no se aceptan mas registros.';
      this.showErrorModal = true;
      this.sending = false;
      return;
    }
    this.catselected = true;
    this.formRegister.patchValue({categoryname: item.nombre});
    this.viewAuth = false;
    if (item.id < 5) {
      this.selectNinos = true;
      this.selectChicas = false;
      this.selectChicos = false;
      this.viewAuth = true;
    } else if (+item.id === 5) {
      this.selectNinos = false;
      this.selectChicas = false;
      this.selectChicos = true;
    } else {
      this.selectNinos = false;
      this.selectChicas = true;
      this.selectChicos = false;
    }
    this.formRegister.patchValue({'camiseta': ''});

  }

  send() {
    if (!this.sending) {
      this.sending = true;
      const formValue = this.formRegister.value;
      const limite = moment('2024-05-05');
      const now = moment();

      if (limite.isSameOrBefore(now)) {
        this.messageErrorModal = 'Lo sentimos, pero ya no se aceptan mas registros.';
        this.showErrorModal = true;
        this.sending = false;
        return;
      }

      if (+formValue.category === 1 || +formValue.category === 5) {
        this.formRegister.patchValue({'dia': '10 de Mayo'});
      } else {
        this.formRegister.patchValue({'dia': '11 de Mayo'});
      }

      if (!formValue.nombre || formValue.nombre === '') {
        this.messageErrorModal = 'No se ha rellenado el nombre del participante.';
        this.showErrorModal = true;
        this.sending = false;
        return;
      }

      if (!formValue.apellidos || formValue.apellidos === '') {
        this.messageErrorModal = 'No se han rellenado los apellidos del participante.';
        this.showErrorModal = true;
        this.sending = false;
        return;
      }

      if (!formValue.fecha || formValue.fecha === '') {
        this.messageErrorModal = 'No se ha rellenado la fecha de nacimiento del participante.';
        this.showErrorModal = true;
        this.sending = false;
        return;
      }

      const date = moment(formValue.fecha, 'DD/MM/YY');
      if (!date.isValid()) {
        this.messageErrorModal = 'El formato de la fecha no es correcto.';
        this.showErrorModal = true;
        this.sending = false;
        return;
      }

      if (!formValue.edad || formValue.edad === '') {
        this.messageErrorModal = 'No se ha rellenado la edad del participante.';
        this.showErrorModal = true;
        this.sending = false;
        return;
      }
      if (+formValue.category === 1 && (formValue.edad > 10 || formValue.edad < 5)) {
        this.messageErrorModal = 'La categoría marcada no coincide con la edad del participante';
        this.showErrorModal = true;
        this.sending = false;
        return;
      }

      if (+formValue.category === 2 && (formValue.edad < 11 || formValue.edad > 17)) {
        this.messageErrorModal = 'La categoría marcada no coincide con la edad del participante';
        this.showErrorModal = true;
        this.sending = false;
        return;
      }

      if (+formValue.category === 3 && (formValue.edad < 18 || formValue.edad > 29)) {
        this.messageErrorModal = 'La categoría marcada no coincide con la edad del participante';
        this.showErrorModal = true;
        this.sending = false;
        return;
      }

      if (+formValue.category === 4 &&  (formValue.edad < 30 || formValue.edad > 49)) {
        this.messageErrorModal = 'La categoría marcada no coincide con la edad del participante';
        this.showErrorModal = true;
        this.sending = false;
        return;
      }

      if (+formValue.category === 5 && formValue.edad < 50) {
        this.messageErrorModal = 'La categoría marcada no coincide con la edad del participante';
        this.showErrorModal = true;
        this.sending = false;
        return;
      }

      if (+formValue.edad < 18) {
        if (!formValue.nombretutor || formValue.nombretutor === '') {
          this.messageErrorModal = 'No se ha rellenado el nombre del tutor y el participante es menor de 18 años.';
          this.showErrorModal = true;
          this.sending = false;
          return;
        }

        if (formValue.autorizado !== true) {
          this.messageErrorModal = 'No se ha rellenado la casilla de autorización y el participante es menor de 18 años.';
          this.showErrorModal = true;
          this.sending = false;
          return;
        }
      }

      if (!formValue.dni || formValue.dni === '') {
        this.messageErrorModal = 'No se ha rellenado el DNI del participante o tutor.';
        this.showErrorModal = true;
        this.sending = false;
        return;
      }

      if (!formValue.telefono || formValue.telefono === '') {
        this.messageErrorModal = 'No se ha rellenado el teléfono del participante o tutor.';
        this.showErrorModal = true;
        this.sending = false;
        return;
      }

      if (!formValue.email || formValue.email === '') {
        this.messageErrorModal = 'No se ha rellenado el email del participante o tutor.';
        this.showErrorModal = true;
        this.sending = false;
        return;
      }

      if (!formValue.postal || formValue.postal === '') {
        this.messageErrorModal = 'No se ha rellenado el código postal del participante o tutor.';
        this.showErrorModal = true;
        this.sending = false;
        return;
      }

      if (formValue.webmn4 !== true && formValue.radio !== true && formValue.amigos !== true &&
        formValue.otro !== true && formValue.redes !== true) {
        this.messageErrorModal = 'Debe rellenarse una opción de cómo nos has conocido';
        this.showErrorModal = true;
        this.sending = false;
        return;
      }

      if (formValue.otro === true && (!formValue.otrodesc || formValue.otrodesc === '')) {
        this.messageErrorModal = 'Se ha marcado otro en la opción de cómo nos ha conocido, pero no se ha rellenado ninguna descripción.';
        this.showErrorModal = true;
        this.sending = false;
        return;
      }

      if (formValue.accept !== true) {
        this.messageErrorModal = 'Se deben de aceptar las bases legales.';
        this.showErrorModal = true;
        this.sending = false;
        return;
      }

      this.api.newReserva(this.formRegister.value).subscribe((data: any) => {
          if (data.error) {
            this.messageErrorModal = data.error;
            this.showErrorModal = true;
            this.sending = false;
          } else {
            this.showSuccessModal = true;
            this.catselected = false;
            this.sending = false;
            this.formRegister.reset();
          }
      }, error => {
        this.messageErrorModal = 'Se ha producido un error en la inscripción. Por favor inténtelo de nuevo';
        this.showErrorModal = true;
        this.sending = false;
      });
    }
  }

  closeModals () {
    this.showErrorModal = false;
    this.showSuccessModal = false;
  }

}
