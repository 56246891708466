import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as global from './global.service';
import { InjectorInstance } from '../app.module';
import { AuthenticationService } from './authentication.service';

@Injectable({providedIn: 'root'})
export class ApicallsService {

  public static uploadFile(file) {
    const httpClient = InjectorInstance.get<HttpClient>(HttpClient);
    const authenticationService = InjectorInstance.get<AuthenticationService>(AuthenticationService);
    const currentUser = authenticationService.currentUserValue;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + currentUser.access_token
    });

    return httpClient.post( global.API_URL + '/api/uploads/uploadimage', file, {
      headers: headers,
    });
  }

  constructor(public http: HttpClient) {

  }

  getAllUsers() {
    return this.http.get(global.API_URL + '/api/users');
  }

  newUser(user) {
    return this.http.post( global.API_URL + '/api/users/register', user);
  }
  newUserLite(user) {
    return this.http.post( global.API_URL + '/api/users/newregisterLite', user);
  }

  editUser(user, id) {
    return this.http.post( global.API_URL + '/api/users/update/' + id, user);
  }
  updateNew(user, id) {
    return this.http.post( global.API_URL + '/api/users/updatenew/' + id, user);
  }

  changePassword(user, id) {
    return this.http.post( global.API_URL + '/api/users/password/' + id, user);
  }

  deleteUser(id) {
    return this.http.delete(global.API_URL + '/api/users/delete/' + id);
  }

  getUser(id) {
    return this.http.get(global.API_URL + '/api/users/view/' + id);
  }

  getAllReservas() {
    return this.http.get(global.API_URL + '/api/reservascasting24');
  }

  newReserva(reserva) {
    return this.http.post( global.API_URL + '/api/reservascasting24/create', reserva);
  }

  getReserva(id) {
    return this.http.get(global.API_URL + '/api/reservascasting24/view/' + id);
  }

  editReserva(reserva, id) {
    return this.http.post( global.API_URL + '/api/reservascasting24/update/' + id, reserva);
  }
  
  deleteReserva(id) {
    return this.http.delete(global.API_URL + '/api/reservascasting24/delete/' + id);
  }

  deleteReservaWithCode(code) {
    return this.http.delete(global.API_URL + '/api/reservascasting24/deletewithcode/' + code);
  }

}



